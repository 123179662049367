import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/esm/Container.js";
import Footer from "../components/footer.js";
import Navbar from "../components/navbar.js";

import copy from "copy-to-clipboard";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useParams } from "react-router-dom";
import config from "../config/index.js";
import { toastAlert } from "../helper/toastAlert";
import contractAbi from "../ABI/MLMContract.json";
import TokenABI from "../ABI/TokenContract.json";
import Web3 from "web3";
import { useDispatch, useSelector } from "react-redux";
import PropagateLoader from "react-spinners/PropagateLoader";
import ConnectWallet from "../components/ConnectWallet.js";

const override = {
  margin: "0 auto",
  borderColor: "red",
  position: "fixed",
  top: "50%",
  left: "50%",
  marginLeft: "-32px",
  marginTop: "-32px",
  zIndex: "99999",
};

export default function JoinNow(props) {
  const { id, pos } = useParams();
  const walletConnection = useSelector((state) => state.walletConnection);

  const [referral, setreferral] = useState(id == undefined ? "" : id);
  const [loader, setLoader] = useState(false);
  const [position, setPosition] = useState(pos == undefined ? "1" : pos);

  async function copyToClipboard(address) {
    copy(address);
    toastAlert("success", "Copied Successfully");
  }

  const handleChange = (event) => {
    setPosition(event.target.value);
  };



  useEffect(() => {
    checkPage();

  }, [walletConnection.address]);

  const checkPage = () => {
    if (walletConnection && walletConnection?.userdetails?.isExist) {
      window.location.href = "/dashboard";
    }
  };

  const joinPlan = async () => {
    try {
      setLoader(true)
      var web3 = new Web3(walletConnection.web3);
      if (referral == "") {
        toastAlert("error", "Referral Address Required");
        setLoader(false)
        return false
      }
      // if (!web3.utils.isAddress(referral)) {
      //   toastAlert("error", "Referral ID is not valid");
      //   setLoader(false)
      //   return false;
      // }

      const ContractToken = new web3.eth.Contract(
        TokenABI,
        config.TokenContract
      );
      var contractcall = new web3.eth.Contract(
        contractAbi,
        config.Contract
      );

      let tokenBalance = await ContractToken.methods
        .balanceOf(walletConnection.address)
        .call();

      console.log(tokenBalance, "tokenBalance");

      let firstPlan = await contractcall.methods.poolInfo("0").call();
      let planAmount = Number(firstPlan.poolAmount) / config.decimalVal;

      if (Number(planAmount) > (Number(tokenBalance) / 1e18)) {
        toastAlert("error", `Your ${config.TokenName} balance is low`);
        setLoader(false)
        return false;
      }

      console.log("saran")

      let estimateGas = await ContractToken.methods
        .approve(config.Contract, (planAmount * 1e18).toString())
        .estimateGas({ from: walletConnection.address })
        .catch((err) => console.log(err));
      console.log(estimateGas,"estimateGasestimateGasestimateGas")
      let gasPriceUsdt = await web3.eth.getGasPrice();
      let estimateGasFee = (estimateGas * gasPriceUsdt) / 1e18;
      // console.log(estimateGasFee, bnbBalance, estimateGasFee > bnbBalance, "edwfced");
      var bnbBalance = await web3.eth.getBalance(walletConnection.address);

      if (estimateGasFee > bnbBalance) {
        toastAlert("error", "Your BNB balance is low");
        setLoader(false)
        return false;
      }
      let referralAddress = await contractcall.methods.userList(referral).call();
      let referralcheck = await contractcall.methods.users(referralAddress).call();

      let Usercheck = await contractcall.methods.users(walletConnection.address).call();

      if (Usercheck.isExist) {
        toastAlert("error", "User already exits");
        setLoader(false)
        return false;
      }
      if (!referralcheck.isExist) {
        toastAlert("error", "Referral not exits");
        setLoader(false)
        return false;
      }

      await ContractToken.methods.approve(config.Contract, (planAmount * 1e18).toString()).send({ from: walletConnection.address }).then(async (res) => {

        await contractcall.methods.joinPlan(referralAddress, position).send({ from: walletConnection.address }).then(async (res) => {
          toastAlert("success", "Join Successfully");
          setTimeout(() => {
            window.location.reload()
          }, 1500);

          setLoader(false)
        })
      })

    } catch (err) {

      console.log(err);
      toastAlert("error", "Transaction Rejected");
      setLoader(false)
    }
  }





  return (
    <>
      <header className="page_header">
        <Navbar />
      </header>
      <section>
        <Container>
          <div className="join_now_section mt-5">
            <h5 className="h5_tag">
              Join <span>Now</span>
            </h5>
            <div className="join_now_page mt-4">
              <Form.Label htmlFor="basic-url" className=" text-center">
                Referral Id :
              </Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Enter referral ID"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  className="custom-placeholder"
                  value={referral}
                  onChange={(e) => setreferral(e.target.value)}
                  disabled={id == undefined ? false : true}
                />
              </InputGroup>
              <div className="join_form_check d-flex gap-3 mt-4">
              <label class="form-check-label" for="inlineRadio1">Choose position :</label>
              <div className="d-flex gap-4">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio1"
                      value="1"
                      checked={position === '1'}
                      onChange={handleChange}
                      disabled={pos == undefined ? false : true}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio1">Left</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                      value="2"
                      checked={position === '2'}
                      onChange={handleChange}
                      disabled={pos == undefined ? false : true}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">Right</label>
                  </div>
                </div>
              </div>
              {/* <div className="join_referral mt-4">
                <div>
                  <h6>Default Id :</h6>
                  <p>{config.defalutAddr}</p>
                  <p>( Note : You have referrals use default id )</p>
                </div>
                <img
                  src={require("../assets/images/dashboard/copy.png")}
                  className="img-fluid"
                  onClick={() => copyToClipboard(config.defalutAddr)}
                />
              </div> */}
              <div className="join_now_btn">
            
                {!walletConnection?.address ? <ConnectWallet /> : 
                    <button className="primary_btn mt-4" onClick={joinPlan}>Submit</button>}
              </div>
            </div>
          </div>
        </Container>
      </section>
      <footer>
        <Footer />
      </footer>
      {loader && (
        <div className="loadBefore">
          <PropagateLoader
            color={"#f6dd07"}
            loading={loader}
            cssOverride={override}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
}
